<template>
  <div>
    <!-- Modal Cek Resi -->
    <b-modal id="m-cek-resi" size="lg" title="Cek Resi" no-close-on-backdrop no-close-on-esc hide-footer>
      <b-row>
        <b-col md="12">
          <b-form @submit.prevent="handleSubmit">
            <b-row>
              <b-col md="5">
                <b-form-group label="No Resi">
                  <b-form-input id="noResi" v-model="form.resi" type="text" required
                    placeholder="Masukan No Resi"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Kurir">
                  <b-form-select id="kurir" placeholder="Pilih Kurir" v-model="form.kurir" :options="[
                    { value: '', text: 'Pilih Kurir' },
                    { value: 'anteraja', text: 'Anter Aja' },
                    { value: 'pos', text: 'POS Indonesia' },
                    { value: 'tiki', text: 'TIKI' },
                  ]" required></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2" align-self="center">
                <b-button type="submit" variant="pgiBtn" class="w-100" :disabled="isSubmitting">
                  <b-spinner v-if="isSubmitting" small label="Loading..."></b-spinner>
                  <span v-else>Cek</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>

          <hr />

          <div v-if="!isSubmitting">
            <div v-if="response != null">
              <VerticalStepper :steps="response.rajaongkir.result.manifest"></VerticalStepper>

              <hr />

              <h5><b>Info Pengiriman</b></h5>

              <table v-if="response.rajaongkir">
                <tr>
                  <td width="80px">Kurir</td>
                  <td width="10px">:</td>
                  <td>{{ response.rajaongkir.result.summary.courier_name || "-" }}</td>
                </tr>
                <tr>
                  <td width="80px">No Resi</td>
                  <td width="10px">:</td>
                  <td>{{ response.rajaongkir.result.summary.waybill_number || "-" }}</td>
                </tr>
                <tr>
                  <td width="80px">Status</td>
                  <td width="10px">:</td>
                  <td>{{ response.rajaongkir.result.summary.status || "-" }}</td>
                </tr>
              </table>

              <div v-else>
                <div>-</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center">
              <b-spinner small label="Loading..."></b-spinner>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-button class="mr-3" title="Cek Resi" variant="pgiBtn" name="cekResiBtn" @click="handleCekResi">
      Cek Resi <i class="fa fa-truck fa-rotate "></i>
    </b-button>
  </div>
</template>

<script>
import VerticalStepper from "./VerticalStepper.vue";

export default {
  name: "CekResi",
  components: {
    VerticalStepper,
  },
  data() {
    return {
      form: {
        resi: "10007389289062",
        kurir: "anteraja",
      },
      isSubmitting: false,
      response: null
    };
  },
  methods: {
    handleCekResi() {
      this.$bvModal.show("m-cek-resi");
    },
    handleSubmit() {
      this.fetchCekResi()
    },
    fetchCekResi() {
      this.isSubmitting = true;
      this.$axios.post("api/transaksi/pengiriman/cek-resi", this.form).then((res) => {
        this.isSubmitting = false;
        this.response = res.data.data
        console.log(this.response)
      });
    },
  },
};
</script>
