<template>
  <div>
    <!-- completed -->
    <!-- <div class="step active">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>Delivery sukses oleh SATRIA dan paket telah diterima oleh
          Aldiansyah(Penerima asli). Terima kasih sudah menggunakan jasa
          AnterAja #PastiBawaHepi</b>

        <div class="tanggal">
          <span>15 Jun 2023, 19:53 WIB</span>
        </div>
      </div>
    </div> -->
    <!-- completed -->
    <!-- <div class="step completed">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>Parcel menuju ke Hub (proses transit).</b>

        <div class="tanggal">
          <span>15 Jun 2023, 19:53 WIB</span>
        </div>
      </div>
    </div> -->
    <!-- completed -->
    <!-- <div class="step completed">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>Parcel sudah tiba di staging untuk menuju ke hub.</b>

        <div class="tanggal">
          <span>15 Jun 2023, 19:53 WIB</span>
        </div>
      </div>
    </div> -->
    <!-- completed -->
    <!-- <div class="step completed">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>Parcel sudah diterima oleh SATRIA.</b>

        <div class="tanggal">
          <span>15 Jun 2023, 19:53 WIB</span>
        </div>
      </div>
    </div> -->
    <!-- completed -->
    <!-- <div class="step completed">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>SATRIA sudah ditugaskan dan parcel akan segera di-pickup.</b>

        <div class="tanggal">
          <span>15 Jun 2023, 19:53 WIB</span>
        </div>
      </div>
    </div> -->

    <!-- completed -->
    <div v-for="(step, index) in steps" :key="step" class="step" :class="index === 0 ? 'active' : 'completed'">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        <b>{{ step.manifest_description }}</b>

        <div class="tanggal">
          <span>{{ step.manifest_date }}, {{ step.manifest_time }}</span>
        </div>
      </div>
    </div>

    <!-- {{ this.steps }} -->

    <!-- empty -->
    <!-- <div class="step empty">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum is simply dummy text of the printing and
        typesetting
      </div>
    </div> -->

    <!-- regular -->
    <!-- <div class="step">
      <div class="v-stepper">
        <div class="circle"></div>
        <div class="line"></div>
      </div>

      <div class="content">
        SATRIA sudah ditugaskan dan parcel akan segera diantar ke penerima.
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "VerticalStepper",
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.container {
  width: 25%;
}

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  /* +6 for border */
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 20px;
  left: 9px;
  /*   height: 120px; */
  height: 100%;
  position: absolute;
  border-left: 3px solid gray;
}

.step.completed .circle {
  visibility: visible;
  background-color: rgb(6, 150, 215);
  border-color: rgb(6, 150, 215);
}

.step.completed .line {
  border-left: 3px solid rgb(6, 150, 215);
}

.step.active .circle {
  visibility: visible;
  border-color: rgb(6, 150, 215);
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
  /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
}
</style>
